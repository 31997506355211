import React, { useState } from "react";
import Layout from "src/components/Layout";
import ArticlesByCategories from "./articlesByCategories/_index";
import { Hero } from "src/pages/sections/hero/_index";
import { navigate } from "gatsby";
import { View } from "src/types/View";
import { Category } from "src/pages/types";
import { useCategoryByName } from "src/hooks/data/useCategoryByName";
import { useArticlesByCategory } from "src/hooks/data/useArticlesByCategory";
import OrangeIcon from "src/components/Icons";
import subcategoriesJson from "src/assets/data/faq-babi/subcategories.json";
import { CategoriesSidebar } from "src/components/CategoriesSidebar";
import { SubCategoriesParser } from "src/models/SubCategory";
import { SidebarModel } from "src/models/ListableCategories";
import * as S from "./style";

type CategoriesPageProps = {
  params: {
    category: string;
  };
};

const BackButton = ({ onClick }: { onClick: () => Promise<void> }) => {
  return (
    <S.Back onClick={onClick}>
      <div>
        <OrangeIcon size="MD" color="#ff7a00" icon="chevron-left" />
      </div>
      Voltar
    </S.Back>
  );
};

const CategoriesView = (req: CategoriesPageProps) => {
  const [submitedSearch, setSubmitedSearch] = useState("");

  const contentMetas = {
    slug: "",
    metaTitle: "Inter. Simplifica a vida.",
    metaDescription: "",
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
    hideBabiChat: false,
  };

  const selectedCategory: Category | undefined = useCategoryByName(
    req.params.category
  );

  const articlesByCategory = useArticlesByCategory(
    (selectedCategory && selectedCategory.id) || ""
  );

  const handleBackToHome = () => navigate("/");

  return (
    <Layout pageContext={contentMetas}>
      <Hero
        useFullWidthSearchBar
        isHomePage
        compactView={true}
        submitedSearch={submitedSearch}
        view={View.ArticleByCategory}
        hasHeaderTitle={false}
      />
      <section className="container mb-4">
        <div className="row">
          <div className="col-12">
            <S.Divisor />
            <BackButton onClick={handleBackToHome} />
          </div>
        </div>
        <div className="row">
          <aside className="col-12 col-md-5 order-last order-md-first">
            <CategoriesSidebar
              listableCategories={SidebarModel.get(
                selectedCategory?.title as string,
                SubCategoriesParser(subcategoriesJson).pt
              )}
            />
          </aside>
          <div className="col-12 col-md-7">
            <S.BreadCrumbWrapper className="mb-4">
              <S.CardBreadCrumb>Central de Relacionamento</S.CardBreadCrumb>
              <div>
                <OrangeIcon size="SM" color="#6A6C72" icon="chevron-right" />
              </div>
              <S.CardBreadCrumb>{selectedCategory?.title}</S.CardBreadCrumb>
            </S.BreadCrumbWrapper>
            <ArticlesByCategories
              categoryTitle={selectedCategory?.title as string}
              categoryUrlParam={req.params.category}
              articlesByCategory={articlesByCategory}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CategoriesView;
